// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Jonathan",
  middleName: "",
  lastName: "Hirsch",
  message: " Passionate about changing the world with technology. ",
  icons: [
    {
      image: "fa-github",
      brand: true,
      url: "https://github.com/jmhirsch",
    },
    {
      image: "fa-linkedin",
      brand: true,
      url: "https://www.linkedin.com/in/jonathan-m-hirsch",
    },
    {
      image: "fa-envelope",
      url: "mailto:jonathan@jonathanhirsch.ca",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: "",
  // imageLink: require("../editable-stuff/hashirshoaeb.png"),
  imageSize: 375,
  message1: "Hey there! I'm Jonathan, a back-end software engineer at Electronic Arts. " +
      "I have a true passion for using technology to create solutions that make a positive impact on the world around us.",
  message2: "I hold a Computer Science degree from the University of British Columbia " +
      "and have a wealth of experience in relational databases, various AWS services, and multiple programming languages such as Java, Kotlin, Golang, and Bash, " +
      " and frameworks and tools such as Springboot, Kubernetes and Helm.",
  message3: "My current work involves designing and building scalable, efficient, and reliable microservices that deliver great experiences to players and internal teams alike," +
       " while meeting the requirements of all stakeholders involved.",
  message4: "I'm always eager to learn and grow, and I'm particularly drawn " +
      "to projects that have the power to improve people's lives. " +
      "If you're looking for someone with a strong background in technology and a passion for making a difference, I could be perfect fit!",
  resume: require("./resume.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Featured Projects",
  gitHubUsername: "jmhirsch", //i.e."johnDoe12Gh"
  reposLength: 0,
  specificRepos: ["ZoomDashboard", "PaxosFailureDetection", "YoutubeTrendingVisualization", "ChainKVS", "ResidenceLifeDBMS", "Homestream"],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/hashirshoaeb.png"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/hashirshoaeb.png"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Java/Kotlin", value: 90 },
    { name: "SQL", value: 85 },
    { name: "Springboot", value: 80 },
    { name: "AWS", value: 75 },
    { name: "K8S/Helm/Docker", value: 70 },
    { name: "Golang", value: 80 },
    { name: "Swift", value: 65 },
    { name: "Bash", value: 75 },
    { name: "C/C++", value: 77 },
    { name: "Data Structures", value: 90 },
  ],
  softSkills: [
    { name: "Collaboration", value: 90 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 90 },
    { name: "Empathy", value: 85 },
    { name: "Organization", value: 85 },
    { name: "Creativity", value: 80 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "I'm always eager to hear about full-time Software Engineering positions! If you know of any positions available, if you have any questions, or if you just want to say hi, please feel free to email me at",
  email: "jonathan@jonathanhirsch.ca",
};

const experiences = {
  show: true,
  heading: "Experiences",
  data: [
    {
      role: 'Software Engineer II', // Here Add Company Name
      companylogo: require('../assets/img/ea.png'),
      date: 'June 2023 – Present',
    },
    {
        role: 'Software Engineer I', // Here Add Company Name
        companylogo: require('../assets/img/ea.png'),
        date: 'August 2022 – May 2023',
    },
    {
      role: 'SWE, Prediction & Telemetry',
      companylogo: require('../assets/img/aero.png'),
      date: 'September 2019 –  May 2020',
    },
    {
      role: 'Technical Specialist',// Here Add Company Name
      companylogo: require('../assets/img/apple.png'),
      date: 'December 2017 – August 2018',
    },
    {
      role: 'Precinct Agent',
      companylogo: require('../assets/img/gs.png'),
      date: 'February 2017 – December 2017',
    },
    {
      role: 'Computing, Sales',
      companylogo: require('../assets/img/bby.png'),
      date: 'October 2015 –  December 2017',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
